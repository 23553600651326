<template>
  <dx-util-popup
    ref="stackedBarChartPopupRef"
    :drag-enabled="false"
    :close-on-outside-click="true"
    :show-close-button="true"
    width="auto"
    height="auto"
    :show-title="false"
    :title="popupTitle"
    :full-screen="false"
    :position="'center'"
    @shown="stackedBarChartPopupShown"
    @hidden="stackedBarChartPopupHidden"
  >
    <div>
      <DxChart
        id="chart"
        :data-source="data"
      >
        <DxCommonSeriesSettings
          argument-field="name"
          type="fullstackedbar"
        />
        <DxTitle
          :text="`Revenue/Cost Distribution Chart`"
          :subtitle="`${chartData.asin}`"
        />
        <DxSeries
          value-field="totalCOGS"
          name="Total COGS"
        />
        <DxSeries
          value-field="miscFee"
          name="Misc Fee"
        />
        <DxSeries
          value-field="mfnShippingFee"
          name="MFN Shipping Fee"
        />
        <DxSeries
          value-field="shippingFee"
          name="Shipping Fee"
        />
        <DxSeries
          value-field="prepFee"
          name="Prep Fee"
        />
        <DxSeries
          value-field="referralFee"
          name="Referral Fee"
        />
        <DxSeries
          value-field="profit"
          name="Profit"
        />
        <DxLegend
          vertical-alignment="top"
          horizontal-alignment="center"
          item-text-position="right"
        />
        <DxExport :enabled="true" />
        <DxTooltip
          :enabled="true"
          :customize-tooltip="customizeTooltip"
        />
      </DxChart>
    </div>
  </dx-util-popup>
</template>

<script>
// import { Notify } from '@/@robustshell/utils'
import {
  DxChart,
  DxSeries,
  DxCommonSeriesSettings,
  DxTitle,
  DxLegend,
  DxExport,
  DxTooltip,
} from 'devextreme-vue/chart'

export default {
  components: {
    DxChart,
    DxSeries,
    DxCommonSeriesSettings,
    DxTitle,
    DxLegend,
    DxExport,
    DxTooltip,
  },
  props: {
    componentId: {
      type: String,
      default: '',
    },
    chartData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      popupTitle: '',
      data: [],
    }
  },
  computed: {
    stackedBarChartPopup() {
      return this.$refs.stackedBarChartPopupRef.instance
    },
  },
  watch: {
    componentId: {
      immediate: true,
      handler() {
        this.stackedBarChartPopup.show()
        this.convertData(this.chartData)
      },
    },
  },
  methods: {
    convertData(data) {
      const parsedData = [{
        name: this.chartData.asin,
        totalCOGS: parseFloat(data.totalCOGS),
        miscFee: parseFloat(data.miscFeePerUnit) * parseInt(data.itemCount, 10),
        mfnShippingFee: parseFloat(data.mfnShipping) * parseInt(data.itemCount, 10),
        shippingFee: parseFloat(data.shippingFeePerUnit) * parseInt(data.itemCount, 10),
        prepFee: parseFloat(data.prepFeePerUnit) * parseInt(data.itemCount, 10),
        fbaFee: parseFloat(data.fbafeePerUnit) * parseInt(data.itemCount, 10),
        referralFee: parseFloat(data.referralFeePerUnit) * parseInt(data.itemCount, 10),
        profit: parseFloat(data.profit),
      }]
      this.data = parsedData
    },
    customizeTooltip({ percentText, valueText }) {
      return ({ text: `${percentText}`, years: `${valueText}` })
    },
    stackedBarChartPopupShown() {
      this.popupTitle = 'Title'
    },
    stackedBarChartPopupHidden() {
    // Clear form when popup is closed
    },
    closePopup() {
      this.stackedBarChartPopup.hide()
    },
    handleSubmit() {
      this.$emit('emit-form-saved')
      this.closePopup()
    },
  },
}
</script>

<style lang="scss" scoped>
#chart {
  height: 800px;
  width: 600px;
}
</style>
